export const Timeline = () => {
    return (

        <div className="cv_columns">

            <strong>2001</strong>
            <div>Started to learn <strong>Turbo Pascal</strong></div>

            <strong>2003</strong>
            <div>Made first applications with <strong>Borland Delphi</strong></div>

            <strong>2005</strong>
            <div>I have mastered <strong>Macromedia Flash</strong> with <strong>ActionScript</strong></div>
            
            <strong>2006</strong>
            <div>I started to learn <strong>HTML+CSS</strong> and <strong>JavaScript</strong></div>
            
            <strong>2007</strong>
            <div>Started to learn <strong>PHP</strong></div>
            
            <strong>2008</strong>
            <div>Made my first commercial project</div>
            
            <strong>2009</strong>
            <div>I learned the basics of <strong>Photoshop</strong> for website design</div>
            
            <strong>2010</strong>
            <div>Started to use <strong>jQuery</strong></div>
            
            <strong>2012</strong>
            <div>Started to make responsive web pages</div>
            
            <strong>2013</strong>
            <div>Mastered the skills with the work of <strong>Sketch</strong></div>
            
            <strong>2014</strong>
            <div>The first acquaintance with <strong>NPM</strong>, <strong>Grunt</strong>, <strong>Gulp</strong></div>
            
            <strong>2017</strong>
            <div>Made first web applications using <strong>React.js</strong></div>
            
            <strong>2018</strong>
            <div>Finished to use jQuery &mdash; only <strong>vanilla JS</strong></div>
            
            <strong>2020</strong>
            <div>Started to learn <strong>Node.js</strong></div>
            
            <strong>2022</strong>
            <div>Begin to study <strong>Docker</strong></div>
            
            <strong>2023</strong>
            <div>GraphQL, Elasticsearch</div>
            
            <strong>2023</strong>
            <div>SSR, Next.js</div>

            <strong>2024</strong>
            <div><strong>Python</strong> &mdash; one love</div>

        </div>
    )
}