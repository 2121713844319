import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'

import { Header } from './components/Header'
import { CV } from './components/CV'
import { Works } from './components/Works'
import { Timeline } from './components/Timeline'
import { Footer } from './components/Footer'

const App = () => {
    return (
        <Router>
            <div className="wrapper">
                <Header />
                <Routes>
                    <Route path="/" element={<CV />} />
                    <Route path="/works" element={<Works />} />
                    <Route path="/timeline" element={<Timeline />} />
                </Routes>
                <Footer />
            </div>
        </Router>
      )
}

export default App
