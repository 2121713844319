export const CV = () => {
    return (
        <div className="cv_columns">

            <strong>BIO</strong>
            <div>
                <p>My name is Bogdan Lapa, and I possess approximately 12 years of professional experience in web development. Throughout this period, I have successfully delivered over 30 e-commerce websites, promotional pages, web applications, and administrative panels. I hold strong proficiency in Sketch and Figma for prototyping interfaces. In 2008, I developed my own CMS and to this day, over 30 websites operate on this platform.</p>
                <p>I have assumed tech/team lead roles, conducted interviews, and provided mentorship to new team members. Additionally, I possess expertise in server administration, search engine optimization, and advanced analytics.</p>
            </div>
            
            <strong>Frontend</strong>
            <div>Responsive pixel-perfect HTML5+CSS3 websites &&nbsp;emails, vanilla JS&nbsp;ES6+, React&nbsp;(4+&nbsp;years), Redux, TypeScript, MUI, Storybook</div>
            
            <strong>Backend</strong>
            <div>SSR, Node.js, Rest&nbsp;API, json, GraphQL, Next.js, php+MySQL, Python, Apache, nginx</div>
            
            <strong>Also</strong>
            <div>Git, Yarn, NPM, SSH, Gulp, Webpack, AWS Amplify, SPA, PWA, WordPress, Keitaro, Palladium</div>

            <strong>Design</strong>
            <div>Photoshop, Sketch, Figma</div>

            <strong>Languages</strong>
            <div>Ukrainian, English (Intermediate B1)</div>
            
            <strong>Hobbies</strong>
            <div>Unreal Engine, Blender, cooking, cycling</div>

            <strong>Work experience</strong>
            <div className="work_experience">
                <div className="work_experience__single">
                    <div><strong>K&K Advertising</strong> &mdash; Frontend Developer</div>
                    <div className="work_experience__single__role">Development cloak with admin panel, creating white sites</div>
                    <div><strong>php, MySQL, Python, React, Redux, TypeScript, Keitaro, AI, Ads</strong></div>
                    <div className="work_experience__single__date">2022&ndash;current time</div>
                </div>
                <div className="work_experience__single">
                    <div><strong>Smartdigit</strong> &mdash; Frontend Developer</div>
                    <div className="work_experience__single__role">Development international b2c e-commerce portal on React</div>
                    <div><strong>React, Redux, TypeScript, Storybook, AWS Amplify</strong></div>
                    <div className="work_experience__single__date">2022&ndash;current time</div>
                </div>
                <div className="work_experience__single">
                    <div><strong>Decorshop.ua</strong> &mdash; Full Stack Developer</div>
                    <div className="work_experience__single__role">Development e-commerce & promo websites, administrative panels</div>
                    <div><strong>php, MySQL, HTML5, CSS3, JS, React, TypeScript</strong></div>
                    <div className="work_experience__single__date">2016&ndash;2022</div>
                </div>
                <div className="work_experience__single">
                    <div><strong>Lucky Labs</strong> &mdash; Frontend Developer</div>
                    <div className="work_experience__single__role">Creating promo landing pages, branding websites</div>
                    <div><strong>HTML, CSS, Vanilla JS, Gulp</strong></div>
                    <div className="work_experience__single__date">2015</div>
                </div>
                <div className="work_experience__single">
                    <div><strong>Freelance</strong> &mdash; Full Stack Developer</div>
                    <div className="work_experience__single__role">Designing, development and maintenance of websites</div>
                    <div><strong>php, MySQL, HTML, JS, jQuery</strong></div>
                    <div className="work_experience__single__date">2011&ndash;2015</div>
                </div>
                <div className="work_experience__single">
                    <div><strong>Ukrainian Center for Educational Quality Assessment</strong></div>
                    <div className="work_experience__single__role">Development applications</div>
                    <div><strong>HTML, CSS, Ajax, php</strong></div>
                    <div className="work_experience__single__date">2008&ndash;2011</div>
                </div>
            </div>

        </div>
    )
}