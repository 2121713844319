import decorshop from './../assets/svg/dedcorshop.svg'
import monolith from './../assets/svg/monolith.svg'
import dekorcarpet from './../assets/svg/dekorcarpet.svg'
import officemix from './../assets/svg/officemix.svg'
import alber from './../assets/svg/alber.svg'
import database from './../assets/svg/database.svg'
import zeusceramica from './../assets/svg/zeusceramica.svg'
import oracdecor from './../assets/svg/oracdecor.svg'
import cascatachuva from './../assets/svg/cascatachuva.svg'

export const Works = () => {
    return (
        <div className="cv_columns">

            <strong>
                <div className="cv_columns__image">
                    <img src={monolith} alt="Monolith UK" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">B2C store and CRM for Monolith UK</div>
                    <div className="cv_columns__description__tech">Project developed a Progressive Web Application (PWA) using React, TypeScript, Redux, and Meilisearch</div>
                    <div className="h3">Key Features</div>
                    <ul className="key_features">
                        <li>Implemented the full range of Progressive Web App features: scanning of barcodes by phone camera, geolocation, browser notifications, etc</li>
                        <li>Utilized React and TypeScript for robust front-end development</li>
                        <li>Leveraged Redux for state management</li>
                        <li>Integrated Meilisearch for powerful search functionality</li>
                        <li>Additionally, integrating Meilisearch for product display via lazy loading reduced the REST API server response time from 1-3 seconds to 100 milliseconds</li>
                    </ul>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={decorshop} alt="Decorshop" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">Online store Decorshop</div>
                    <div className="cv_columns__description__website"><a href="https://decorshop.ua/eng/" target="_blank" rel="nofollow noreferrer noopener">decorshop.ua</a></div>
                    <div className="cv_columns__description__tech">Client side: monolith php+MySQL+js, admin side: React+Typescript+Redux</div>
                    <div className="h3">Key Features</div>
                    <ul className="key_features">
                        <li>Online store with over 250,000 products</li>
                        <li>Administrating 20+ additional client websites through the store's admin panel</li>
                        <li>Developed and maintained a variety of online calculators to assist users</li>
                    </ul>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={cascatachuva} alt="Decorshop" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">Cascata Chuva</div>
                    <div className="cv_columns__description__website"><a href="https://cascatachuva.eu/" target="_blank" rel="nofollow noreferrer noopener">cascatachuva.eu</a></div>
                    <div className="cv_columns__description__tech">HTML+CSS+JS</div>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={oracdecor} alt="Orac Decor" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">Online store Orac Decor</div>
                    <div className="cv_columns__description__website"><a href="https://oracdecor.in.ua/ua/" target="_blank" rel="nofollow noreferrer noopener">oracdecor.in.ua</a></div>
                    <div className="cv_columns__description__tech">Client side: monolith php+MySQL+js</div>
                    <div className="h3">Key Features</div>
                    <ul className="key_features">
                        <li>Synchronized with the Decorshop database</li>
                        <li>Managed through the Decorshop admin panel</li>
                    </ul>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={alber} alt="Alber" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">Official website of Ukrainian brand of window sills</div>
                    <div className="cv_columns__description__website"><a href="https://alber.com.ua/en/" target="_blank" rel="nofollow noreferrer noopener">alber.com.ua</a></div>
                    <div className="cv_columns__description__tech">Simple landing page with shopping cart</div>
                    <div className="h3">Key Features</div>
                    <ul className="key_features">
                        <li>Synchronized with the Decorshop database</li>
                        <li>Managed through the Decorshop admin panel</li>
                    </ul>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={officemix} alt="Office-Mix" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">Online store Office-Mix</div>
                    <div className="cv_columns__description__website"><a href="https://office-mix.com.ua/ua/" target="_blank" rel="nofollow noreferrer noopener">office-mix.com.ua</a></div>
                    <div className="cv_columns__description__tech">Client side: monolith php+MySQL+js, admin side: React+Typescript+Redux</div>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={dekorcarpet} alt="Dekor Carpet" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">Online store Dekor Carpet</div>
                    <div className="cv_columns__description__website"><a href="https://dekor-carpet.com.ua/en/" target="_blank" rel="nofollow noreferrer noopener">dekor-carpet.com.ua</a></div>
                    <div className="cv_columns__description__tech">Client side: monolith php+MySQL+js, admin side: React+Typescript+Redux</div>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={zeusceramica} alt="Zeus Ceramica" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">Monobrand store of Zeus Ceramica</div>
                    <div className="cv_columns__description__website"><a href="https://zeus-ceramica.com.ua/ukr/" target="_blank" rel="nofollow noreferrer noopener">zeus-ceramica.com.ua</a></div>
                    <div className="cv_columns__description__tech">Client side: monolith php+MySQL+js, admin side: React+Typescript+Redux</div>
                </div>
            </div>

            <strong>
                <div className="cv_columns__image">
                    <img src={database} alt="Database" />
                </div>
            </strong>
            <div>
                <div className="cv_columns__description">
                    <div className="cv_columns__description__title">An analog of Sequel Pro on React</div>
                    <div className="cv_columns__description__tech">It's an application for comfortable work with database MySQL</div>
                    <div className="h3">Key Features</div>
                    <ul className="key_features">
                        <li>Compared to PHPMyAdmin, this application has a light and thoughtful interface</li>
                        <li>Sorting data by different parameters and characteristics</li>
                        <li>Automatically translation on Ukrainian and English</li>
                        <li>It is possible to print price tags with QR codes</li>
                        <li>Instant uploading of images without reloading the page</li>
                        <li>Keyboard control</li>
                        <li>Templates of columns</li>
                    </ul>
                </div>
            </div>

        </div>
    )
}